import {isFeatureEnabled} from '@github-ui/feature-flags'
import {startTransition} from 'react'

export function flaggedStartTransition(cb: () => void) {
  if (isFeatureEnabled('react_start_transition_for_navigations')) {
    return startTransition(cb)
  }

  return cb()
}
