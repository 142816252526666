import {useNavigator} from './use-navigator'
import {useRef} from 'react'
import {useLayoutEffect} from '@github-ui/use-layout-effect'
import {Router} from 'react-router-dom'
import type {History} from '@remix-run/router'
import {ErrorBoundary} from './ErrorBoundary'
import type {EmbeddedData} from './embedded-data-types'
import type {AppRegistration} from './react-app-registry'
import {BaseProviders} from './BaseProviders'
import {CommonElements} from './CommonElements'
import {useSoftNavLifecycle} from './use-soft-nav-lifecycle'
import {useNavigationFocus} from './use-navigation-focus'
import {useTitleManager} from './use-title-manager'
import {useScrollRestoration, installScrollRestoration} from './use-scroll-restoration'
import {createBrowserHistory} from './create-browser-history'
import type {AppComponentType} from './AppWrapper'
import {AppRouter} from './AppRouter'

installScrollRestoration()

interface Props {
  appName: string
  initialPath: string
  embeddedData: EmbeddedData
  routes: AppRegistration['routes']
  App?: AppComponentType
  wasServerRendered: boolean
  ssrError?: HTMLScriptElement
}

export function ClientEntry({appName, initialPath, embeddedData, routes, App, wasServerRendered, ssrError}: Props) {
  // Create a ref to track the browser history:
  const historyRef = useRef<History>()
  const window = globalThis.window as Window | undefined

  // Initial path is set by ruby. Anchors are not sent to the server.
  // Therefore anchors must be set explicitly by the client.
  const {pathname, search, hash} = new URL(
    `${initialPath}${window?.location.hash ?? ''}`,
    window?.location.href ?? 'https://github.com',
  )

  if (!historyRef.current) {
    historyRef.current = createBrowserHistory({window, v5Compat: true})
  }
  const history = historyRef.current
  const {key, state} = history.location

  // We create our "app" here. The app is a state machine that lets you dispatch a history update
  // and gives you a resolved location (after e.g., loading, redirects, etc.)
  const [{location, error, routeStateMap, appPayload, navigateOnError, isLoading}, {handleHistoryUpdate}] =
    useNavigator({
      initialLocation: {
        pathname,
        search,
        hash,
        key,
        state,
      },
      appName,
      embeddedData,
      routes,
    })

  useTitleManager(routeStateMap[location.key]!, error, location)
  useNavigationFocus(isLoading, location)
  useSoftNavLifecycle(location, isLoading, error, appName)
  useScrollRestoration()

  // When we get a history update, we send it to our app via handleHistoryUpdate
  // Note, we only want this to run in the browser to avoid SSR warnings about useLayoutEffect
  useLayoutEffect(() => {
    const unlisten = history.listen(handleHistoryUpdate)
    return unlisten
  }, [history, handleHistoryUpdate])

  return (
    <BaseProviders appName={appName} wasServerRendered={wasServerRendered}>
      <ErrorBoundary>
        <AppRouter
          App={App}
          appPayload={appPayload}
          error={error}
          history={history}
          location={location}
          navigateOnError={navigateOnError}
          Router={Router}
          routes={routes}
          routeStateMap={routeStateMap}
        >
          <CommonElements ssrError={ssrError} />
        </AppRouter>
      </ErrorBoundary>
    </BaseProviders>
  )
}

try{ ClientEntry.displayName ||= 'ClientEntry' } catch {}